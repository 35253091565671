import ArticlesModel from '@/features/common/models/articlesModel'
import ArticlesDtoResult from '@models/articlesDtoResult'
import ArticleModel from '@/features/common/models/articleModel'
import ArticleDto from '@models/articleDto'
import { CreateArticleDto } from '@models/createArticleDto'
import { UpdateAdminArticleDto } from '@models/updateAdminArticleDto'

export namespace ArticleModelMapper {
  export const createMapToRequest = (articleModel: ArticleModel, uuid: string): CreateArticleDto.Request => ({
    uuid: uuid,
    background: articleModel.background,
    category: articleModel.category,
    contentList: articleModel.contentList,
    date: articleModel.date,
    description: articleModel.description,
    isOpen: articleModel.isOpen,
    ogImage: articleModel.ogImage,
    openDateTime: articleModel.openDateTime || '',
    tag: articleModel.tag,
    thumbnail: articleModel.thumbnail,
    title: articleModel.title,
  })
  export const updateMapToRequest = (
    prevArticleModel: ArticleModel,
    articleModel: ArticleModel,
    uuid: string,
  ): UpdateAdminArticleDto.Request => ({
    id: prevArticleModel?.id || 0,
    category: prevArticleModel?.category || '',
    uuid: uuid,
    item: {
      id: prevArticleModel?.id,
      background: articleModel.background,
      category: articleModel.category,
      contentList: articleModel.contentList,
      date: articleModel.date,
      description: articleModel.description,
      ogImage: articleModel.ogImage,
      tag: articleModel.tag,
      thumbnail: articleModel.thumbnail,
      title: articleModel.title,
      isOpen: articleModel.isOpen,
      openDateTime: articleModel.openDateTime,
    },
  })

  /**
   * api 를 통해서 받은 Articles 를 UI 에서 사용하기 위한 ArticlesModel 로 변환하는 함수
   * @param contentsList
   */
  export const mapsToPresentation = (contentsList: ArticlesDtoResult): ArticlesModel => ({
    list: contentsList.list.map((contentsItemModel: ArticleDto) => mapToPresentation(contentsItemModel)),
    totalCount: contentsList.totalCount,
  })

  export const mapToPresentation = (contentsItem: ArticleDto): ArticleModel => ({
    background: contentsItem.background,
    category: contentsItem.category,
    contentList: contentsItem.contentList,
    date: contentsItem.date,
    description: contentsItem.description,
    id: contentsItem.id,
    ogImage: contentsItem.ogImage,
    tag: contentsItem.tag,
    thumbnail: contentsItem.thumbnail || '',
    title: contentsItem.title,
    isOpen: contentsItem.isOpen,
    openDateTime: contentsItem.openDateTime,
  })
}
