import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Collapse, Keyword, PartnerCard, ReviewArticle } from '@/features/common/components'
import style from './Reviews.module.scss'

import { PARTNER_COMPANIES } from '@/constants/corporations'
import { KeywordColorType } from '@/features/common/types/keywordColorType'
import Image from 'next/image'
import Ai from '@/assets/images/ai.png'
import Driver from '@/assets/images/driver.png'
import Owner from '@/assets/images/owner.png'
import { useCustomMediaQuery } from '@/features/common/hooks/useCustomMediaQuery'
//import { useFadeInAnimation } from '@/features/common/hooks/useFadeInAnimation'
import LeftArrow from '@/features/service/components/arrow/LeftArrow'
import RightArrow from '@/features/service/components/arrow/RightArrow'
import { useInterviewHandler } from '@/features/home/hooks/useInterviewHandler'
import SwiperSlider from '@/features/common/components/SwiperSlider'

const settings = {
  slidesPerRow: 1,
  infinite: false,
  swipeToSlide: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
}

function Reviews() {
  //const { animationFadeInUp } = useFadeInAnimation()
  const { UpToTablet, Desktop, Tablet, Mobile, isMobile } = useCustomMediaQuery()
  const { articlesModel } = useInterviewHandler()

  return (
    <section className={style.container}>
      <div className={style.container__title}>
        <h2>진짜, 더 운반 사용기</h2>
        <UpToTablet>
          <p>
            <span>사용해보신 화주님들이 더 운반을 추천하는 이유를 확인하세요.</span>
          </p>
        </UpToTablet>
        <Mobile>
          <p>
            <span>사용해보신 화주님들이 더 운반을 추천하는</span>
            <span>이유를 확인하세요.</span>
          </p>
        </Mobile>
      </div>
      <Collapse title='화주님이 들려주시는 더 운반' isOpen>
        <div className={style.article__container}>
          <UpToTablet>
            {articlesModel.list.map((articleModel, index) => (
              <div key={index}>
                <ReviewArticle key={index} articleModel={articleModel} />
              </div>
            ))}
          </UpToTablet>
          <Mobile>
            <Slider {...settings}>
              {articlesModel.list.map((articleModel, index) => (
                <div key={index}>
                  <ReviewArticle key={index} articleModel={articleModel} />
                </div>
              ))}
            </Slider>
          </Mobile>
        </div>
      </Collapse>
      <Collapse title='더 운반과 함께하는 기업' isOpen>
        <UpToTablet>
          <SwiperSlider
            spaceBetween={8}
            slidesPerView='auto'
            freeMode
            slideStyle={{ width: 'auto' }}
            className={style.partner__container}
          >
            {PARTNER_COMPANIES.map((partner, index) => (
              <PartnerCard key={index} image={partner.image} name={partner.name} link={partner.link} />
            ))}
          </SwiperSlider>
        </UpToTablet>
        <Mobile>
          <div className={style.partner__container}>
            {PARTNER_COMPANIES.map((partner, index) => (
              <PartnerCard key={index} image={partner.image} name={partner.name} link={partner.link} />
            ))}
          </div>
        </Mobile>
      </Collapse>
      <Collapse title={isMobile() ? '더 운반 keyword!' : '화주분들이 생각하는 더 운반 keyword!'}>
        <div className={style.keyword__container}>
          <div className={style.keyword__container__sentence}>
            <p>
              <Image src={Owner} alt='화주 이미지' />
            </p>
            <p>
              <span>화주와</span>
            </p>
            <p>
              <Image src={Driver} alt='차주 이미지' />
            </p>
            <p>
              <span>차주와</span>
            </p>
            <Keyword keyword='직접연결' colorType={KeywordColorType.SECONDARY} />
            <Keyword keyword='수수료 무료' />
            <Keyword keyword='편리함' />
            <Keyword keyword='운임절감' />
            <Desktop>
              <p>
                <span>까지 해주는 착한 플랫폼이다.</span>
              </p>
            </Desktop>
            <Tablet>
              <p>
                <span>까지 해주는 착한</span>
              </p>
              <p>
                <span>플랫폼이다.</span>
              </p>
            </Tablet>
          </div>
          <div className={style.keyword__container__sentence}>
            <p>
              <span>1천만건의 물류 데이터를 학습한</span>
            </p>
            <p>
              <Image src={Ai} alt='ai 이미지' />
            </p>
            <p>
              <span>AI가</span>
            </p>
            <Keyword keyword='실시간' />
            <Keyword keyword='최적운임제공' colorType={KeywordColorType.SECONDARY} />
            <Keyword keyword='적합한매칭' />
            <p>
              <span>으로 품질 만족이에요!</span>
            </p>
          </div>
          <div className={style.keyword__container__sentence}>
            <p>
              <span>전화, 문자 대신 웹사이트에서 실시간으로</span>
            </p>
            <Keyword keyword='운송상태 확인' colorType={KeywordColorType.SECONDARY} />
            <Keyword keyword='화물정보' />
            <p>
              <span> 바로 가능해서 물어볼 필요가 없어요.</span>
            </p>
          </div>
        </div>
      </Collapse>
    </section>
  )
}

export default Reviews
